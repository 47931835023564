.top{
    font-weight: 200;
    font-size: 4rem;
    color: rgb(255, 255, 255);
    margin-bottom: 3%;
}

h4{
    font-weight: 300;
    font-size: 2.5rem;
}

.contact{
    background-color: rgb(37, 37, 37);
    text-align: center;
    padding: 6rem;
    font-weight: lighter;
}

 .tags{
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 2.5rem;
}
