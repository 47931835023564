.footer{
    background-color: black;
    margin: 0;
    color: white;
    text-align: center;
    padding: 2rem;
}

h3{
    font-weight: light;
    font-size: 1.2rem;
}

h6{
    font-weight: light;
    font-size: 0.8rem;
}