.heading{
    font-size: 4rem;
    padding: 10%;
    padding-bottom: 1%;
    background-color: rgb(34, 34, 34);
    place-content: center;
    text-align: center;
    color: white;
    text-align: center;
    position: relative; 


}

.big{
    font-size: 8rem;
}
.heading :hover{
    transition: 0.5s;
    transform: skewX(5deg);
    color: rgb(167, 167, 167);
}

.hero{
    position: relative;
    margin-left: auto;
    margin-right: auto;
 

}

.motion-background{
    font-size: 4rem;

    background-color: rgb(34, 34, 34);
    place-content: center;
    text-align: center;
    color: white;
    text-align: center;
    position: relative; 
}