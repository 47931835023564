#progressBar {
  width: 0%;
  height: 5px;
  background-color: blue;
  transition: width 0.5s;
}

.progressAnimation {
  animation: progress 3s linear;
}

@keyframes progress {
  0% { width: 0%; }
  100% { width: 100%; }
}